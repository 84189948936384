import React, { useState } from "react";
import {
  IonButton,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { IonCard } from "@ionic/react";

import "./AboutCard.css"; // Import your custom CSS file

const AboutCard: React.FC = () => {
  const [cardData, setCardData] = useState([
    {
      short_content:
        "Ark Web Development serves Toledo - Port Clinton and beyond, prioritizing strong customer relationships. As fellow small business owners, we value personal connections and are available to support you beyond normal business hours...",
      full_content:
        "Ark Web Development serves Toledo - Port Clinton and beyond, prioritizing strong customer relationships. As fellow small business owners, we value personal connections and are available to support you beyond normal business hours. Our passionate team of entrepreneurs is dedicated to fostering the growth of your start-up or small business. With cutting-edge technology and progressive web apps, we create fast, affordable, and mobile-optimized websites that attract loyal customers and effectively convey your brand. Let us help you reach your engagement and conversion rate goals with our user-friendly solutions. Partner with Ark Web Development for exceptional web development and unlock your business's online potential.",
      isFlipped: false,
    },
  ]);

  const handleFlip = (index: number) => {
    setCardData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        isFlipped: !newData[index].isFlipped,
      };
      return newData;
    });
  };

  return (
    <div>
      <h2 className="section-heading">About ARK</h2>
      <IonGrid>
        <IonRow>
          <IonCol size="0" sizeLg="2"></IonCol>
          {cardData.map((card, index) => (
            <IonCol key={index} size="12" sizeLg="8">
              <IonCard className="about-card">
                {!card.isFlipped ? (
                  <IonCardContent className="card-item-align">
                    <div className="about-card-p">{card.short_content}</div>
                    <IonButton
                      className="about-learn-more"
                      shape="round"
                      onClick={() => handleFlip(index)}>
                      Read More
                    </IonButton>
                  </IonCardContent>
                ) : (
                  <IonCardContent className="card-item-align">
                    <div className="about-card-p">{card.full_content}</div>
                    <IonButton
                      className="back-button"
                      shape="round"
                      onClick={() => handleFlip(index)}>
                      Read Less
                    </IonButton>
                  </IonCardContent>
                )}
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <br />
      <br />
    </div>
  );
};

export default AboutCard;
