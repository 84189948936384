// Footer.jsx

import React, { useRef, useState } from "react";
import {
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonButton,
  IonToast,
} from "@ionic/react";
import { mailOutline, callOutline, star } from "ionicons/icons";
import "./Footer.css"; // Import your CSS file
import arklogo from "../../assets/logos/arkwWords.png";

const Footer = () => {
  const [copied, setCopied] = useState(false);
  const toastRef = useRef(null);
  const phoneNumber = "4193189839";

  // Check if the user is on a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const handleClick = () => {
    // Replace 'your-email@example.com' with your actual email address
    const emailAddress = "kyle@arkwd.com";
    window.open(`mailto:${emailAddress}`, "_blank");
  };
  const handlePhone = () => {
    if (isMobile) {
      // If on mobile, prompt the user to call
      window.location.href = `tel:${phoneNumber}`;
    } else {
      // If not on mobile, copy the phone number to the clipboard
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
          // alert(`Phone number ${phoneNumber} copied to clipboard`);
        })
        .catch((error) => {
          console.error("Failed to copy phone number: ", error);
        });
    }
    // const phoneNumber = "1234567890";
    // // window.open(`tel:${phoneNumber}`);
    // window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <IonFooter className="home-footer">
      <IonGrid className="footer-container ion-text-center">
        <IonRow>
          <IonCol>
            <IonImg src={arklogo} className="footer-logo"></IonImg>
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center">
          <IonButton fill="clear" onClick={handleClick} size="large">
            <IonIcon icon={mailOutline} slot="start"></IonIcon>
            Email us
          </IonButton>
          <IonButton onClick={handlePhone} size="large" fill="clear">
            Call us
            <IonIcon icon={callOutline} slot="start"></IonIcon>
          </IonButton>
        </IonRow>

        <IonRow>
          <IonCol>
            <br />
            &copy; {new Date().getFullYear()} Ark Web Development. All rights
            reserved.
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonToast
        ref={toastRef}
        isOpen={copied}
        message="Copied 1234567890 to clipboard"
        duration={1000}
      />
    </IonFooter>
  );
};

export default Footer;
