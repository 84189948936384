import React, { useRef, useState } from "react";
import axios from "axios"; // Import Axios
import {
  IonCard,
  IonCardContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
  IonImg,
  IonIcon,
  IonToast,
} from "@ionic/react";
import "./ContactForm.css"; // Make sure to import your styles
import LogoWithWords from "../../assets/logos/arkwWords.png";
import { callOutline, mailOutline } from "ionicons/icons";
interface ContactForm {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}
const ContactForm: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const [submitted, setsubmitted] = useState(false);
  const toastRef = useRef(null);
  const phoneNumber = "4193189839";

  // Check if the user is on a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const handleClick = () => {
    // Replace 'your-email@example.com' with your actual email address
    const emailAddress = "kyle@arkwd.com";
    window.open(`mailto:${emailAddress}`, "_blank");
  };
  const handlePhone = () => {
    if (isMobile) {
      // If on mobile, prompt the user to call
      window.location.href = `tel:${phoneNumber}`;
    } else {
      // If not on mobile, copy the phone number to the clipboard
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1000);
          // alert(`Phone number ${phoneNumber} copied to clipboard`);
        })
        .catch((error) => {
          console.error("Failed to copy phone number: ", error);
        });
    }
  };
  const [contactForm, setContactForm] = useState<ContactForm>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const submitContact = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Validate form fields before submitting
      if (
        !contactForm.firstname ||
        !contactForm.lastname ||
        !contactForm.email ||
        !contactForm.subject ||
        !contactForm.message
      ) {
        // Handle validation error, maybe show a message to the user
        console.error("Please fill out all required fields.");
        return;
      }

      // Send data to Discord using Axios
      const webhookUrl =
        "https://discord.com/api/webhooks/1115040802138243142/DEbe4ou5JQISbo_TAFiphlSDhqe_8pbEO1AHo2ZW5QQK8UL-DoYbmsEneddn3fzmoKg1";
      await axios.post(webhookUrl, {
        content: `New form submission:\n\nFirst Name: ${contactForm.firstname}\nLast Name: ${contactForm.lastname}\nEmail: ${contactForm.email}\nPhone: ${contactForm.phone}\nSubject: ${contactForm.subject}\nMessage: ${contactForm.message}`,
      });

      // Optionally, you can reset the form after successful submission
      setContactForm({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      console.log("Form submitted successfully!");
      setsubmitted(true);
      setTimeout(() => {
        setsubmitted(false);
      }, 1000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="contact-container">
      <h2 className="section-heading">Contact Us</h2>
      <IonCard className="contact-card">
        <IonCardContent>
          <IonImg src={LogoWithWords} class="form-logo"></IonImg>
          <div className="contact-p">
            <IonText>
              Get in touch with us! Fill out the form with your details, and we
              will be in touch soon.
            </IonText>
            <br />
            <br />
            <IonButton fill="clear" size="large" onClick={handleClick}>
              <IonIcon icon={mailOutline} slot="start"></IonIcon>
              Email us
            </IonButton>
            <IonButton size="large" onClick={handlePhone} fill="clear">
              Call us
              <IonIcon icon={callOutline} slot="start"></IonIcon>
            </IonButton>
          </div>

          {/* <IonRow class="ion-justify-content-center"> */}

          <form onSubmit={submitContact} className="contact-form">
            <IonGrid fixed>
              {/*first and last name */}
              <IonRow>
                <IonCol size="6">
                  {/* name */}
                  <IonInput
                    value={contactForm.firstname}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        firstname: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label=" First Name"
                    labelPlacement="stacked"
                    fill="outline"
                    placeholder="John"
                    required={true}
                    mode="md"></IonInput>
                </IonCol>
                <IonCol size="6">
                  {/* last name */}
                  <IonInput
                    value={contactForm.lastname}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        lastname: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label="Last Name"
                    labelPlacement="stacked"
                    fill="outline"
                    placeholder="Doe"
                    required={true}
                    mode="md"></IonInput>
                </IonCol>
              </IonRow>

              {/* phone and email */}
              <IonRow>
                <IonCol size="6">
                  {/* email */}
                  <IonInput
                    value={contactForm.email}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        email: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label="Email"
                    labelPlacement="stacked"
                    fill="outline"
                    placeholder="johndoe@email.com"
                    required={true}
                    mode="md"
                    type="email"
                    inputmode="email"></IonInput>
                </IonCol>
                <IonCol size="6">
                  {/* phone */}
                  <IonInput
                    value={contactForm.phone}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        phone: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label="Phone"
                    labelPlacement="stacked"
                    fill="outline"
                    placeholder="123-456-7890"
                    mode="md"
                    type="tel"
                    inputmode="numeric"
                    minlength={10}
                    maxlength={11}></IonInput>
                </IonCol>
              </IonRow>

              {/* subject */}
              <IonRow>
                <IonCol size="12">
                  {/* Subject */}
                  <IonInput
                    value={contactForm.subject}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        subject: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label="Subject"
                    labelPlacement="stacked"
                    fill="outline"
                    placeholder="Make me a super cool website!"
                    required={true}
                    mode="md"
                    maxlength={20}></IonInput>
                </IonCol>
              </IonRow>

              {/* Message */}
              <IonRow>
                <IonCol size="12">
                  {/* Message */}
                  <IonTextarea
                    value={contactForm.message}
                    onIonChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        message: e.detail.value as string,
                      })
                    }
                    className="contact-form-input"
                    label="Message"
                    labelPlacement="stacked"
                    fill="outline"
                    mode="md"
                    placeholder="I want a cool new website that..."
                    maxlength={280}
                    auto-grow={true}
                    rows={10}></IonTextarea>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol class="ion-text-center">
                  <IonButton
                    size="large"
                    mode="md"
                    type="submit"
                    fill="outline"
                    shape="round"
                    expand="block">
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      <IonToast
        ref={toastRef}
        isOpen={copied}
        message="Copied 419-318-9839 to clipboard"
        duration={1000}
      />
      <IonToast
        ref={toastRef}
        isOpen={submitted}
        message="Form recieved! Thank you!"
        duration={1000}
      />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ContactForm;
