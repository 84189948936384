import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";
import "./FloatingToolbar.css"; // Import your custom CSS file
import logo from "../../assets/logos/Arkwebdevlogo.svg";

interface FloatingToolbarProps {
  activeSection: string;
}
const FloatingToolbar: React.FC<FloatingToolbarProps> = ({ activeSection }) => {
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Adds smooth scrolling effect
        block: "start",
      });
    }
  };
  const refreshPage = () => {
    window.location.reload();
    // Alternatively, you can use window.location.reload() without passing true
    // to perform a regular reload without using the cache.
  };
  return (
    <div className="my-toolbar">
      <IonButtons slot="end">
        <IonButton
          className="logoIcon"
          size="large"
          mode="md"
          onClick={refreshPage}>
          <IonIcon slot="icon-only" icon={logo}></IonIcon>
        </IonButton>
      </IonButtons>
      <div className="expandedComps">
        <IonSegment value={activeSection} mode="md">
          <IonSegmentButton
            value="about"
            mode="md"
            onClick={() => scrollToSection("about")}>
            <IonLabel color={activeSection === "about" ? "primary" : "medium"}>
              About
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="services"
            mode="md"
            onClick={() => scrollToSection("services")}>
            <IonLabel
              color={activeSection === "services" ? "primary" : "medium"}>
              Services
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            value="contact"
            mode="md"
            onClick={() => scrollToSection("contact")}>
            <IonLabel
              color={activeSection === "contact" ? "primary" : "medium"}>
              Contact
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      <IonButtons slot="end">
        <IonButton mode="md" onClick={() => scrollToSection("home")}>
          <IonIcon
            size="large"
            slot="icon-only"
            icon={arrowUpOutline}></IonIcon>
        </IonButton>
      </IonButtons>
    </div>
  );
};

export default FloatingToolbar;
