import React, { useEffect, useRef, useState } from "react";
import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import "./Home.css";
import FloatingToolbar from "../components/FloatingToolbar/FloatingToolbar";
import HomeLanding from "../components/HomeLanding/HomeLanding";
import Services from "../components/Services/Services";
import ContactForm from "../components/ContactForm/ContactForm";
import AboutCard from "../components/AboutCard/AboutCard";
import Footer from "../components/Footer/Footer";
import logo from "../assets/logos/Arkwebdevlogo.svg";

const Home: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("About");

  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const servicesSectionRef = useRef<HTMLDivElement>(null);
  const contactSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    if (aboutSectionRef.current) observer.observe(aboutSectionRef.current);
    if (servicesSectionRef.current)
      observer.observe(servicesSectionRef.current);
    if (contactSectionRef.current) observer.observe(contactSectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const isMobileDevice = () => {
    // Adjust the threshold value based on your criteria for mobile devices
    const mobileThreshold = 768; // Example threshold value for mobile devices

    return window.innerWidth < mobileThreshold;
  };
  const refreshPage = () => {
    window.location.reload();
    // Alternatively, you can use window.location.reload() without passing true
    // to perform a regular reload without using the cache.
  };
  return (
    <IonPage title="Ark Web Development">
      <IonContent fullscreen class="contenu">
        <IonButton
          className="logoIcon"
          size="large"
          mode="md"
          fill="clear"
          onClick={refreshPage}>
          <IonIcon slot="icon-only" icon={logo}></IonIcon>
        </IonButton>
        <section id="home">
          <HomeLanding></HomeLanding>
        </section>

        <section id="about" ref={aboutSectionRef}>
          <AboutCard></AboutCard>
        </section>
        <section id="services" ref={servicesSectionRef}>
          <Services></Services>
        </section>
        <section id="contact" ref={contactSectionRef}>
          <ContactForm></ContactForm>
        </section>
        <Footer></Footer>
      </IonContent>
      {!isMobileDevice() && <FloatingToolbar activeSection={activeSection} />}
    </IonPage>
  );
};

export default Home;
