import React from "react";

import Lottie from "react-lottie-player/dist/LottiePlayerLight";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

// import lottieJson from '../lottie/construction.json'

interface LottieProps {
  lottieJson: unknown;
  lottieWidth: string;
  lottieHeight: string;
  lottiePadding?: string;
  lottieMargin?: string;
  lottieSpeed?: number;
  //style: React.CSSProperties;
}
const LottiePlayer: React.FC<LottieProps> = ({
  lottieJson,
  lottieSpeed = 1,
  lottieWidth,
  lottieMargin = "0,0,0,0",
  lottiePadding = "0,0,0,0",
  lottieHeight,
}) => {
  return (
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{
        width: lottieWidth,
        height: lottieHeight,
        padding: lottiePadding,
        margin: lottieMargin,
      }}
      speed={lottieSpeed}
    />
  );
};
export default LottiePlayer;
